import cn from "classnames";

import { ReactComponent as SortAscIcon } from "assets/icons/sort-asc.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/sort-desc.svg";
import { UserDetails, Tag, PortalTooltip, Image, Icon } from "components";
import { textToArrayFormatter } from "utils";

import styles from "./Activities.module.sass";

const integrationIcons = {
  confluence: { img: "/images/integration/confluence.png" },
  github: { img: "/images/integration/github.png", full: true },
  google: { img: "/images/integration/google.png" },
  jira: { img: "/images/integration/jira.png" },
  slack: { img: "/images/integration/slack.png" },
  zoom: { img: "/images/integration/zoom.png", full: true },
  teams: { img: "/images/integration/teams.png" },
  outlook: { img: "/images/integration/exchangeonline.png" },
  salesforce: { img: "/images/integration/salesforce.png" },
};

const integrationColumns = (
  integrationTabs,
  sort,
  onSort,
  integrationStatuses = {}
) =>
  integrationTabs?.map((integration, index) => ({
    title: (
      <PortalTooltip
        text={
          integrationStatuses[integration.toLocaleLowerCase()] === "offline" ? (
            <div className={styles.offlineWarn}>
              <span className={styles.warnTitle}>Integration Status</span>
              <span className={styles.warnText}>Currently Offline</span>
            </div>
          ) : null
        }
        position="top"
      >
        <div
          className={cn(styles.col, {
            [styles.selected]: sort.sort_by === `${integration}_sum`,
          })}
        >
          <span onClick={() => onSort(`${integration}_sum`)}>
            {sort.sort_by === `${integration}_sum` &&
            sort.sort_order === "desc" ? (
              <SortAscIcon />
            ) : (
              <SortDescIcon />
            )}
          </span>
          <div
            className={cn(styles.colIcon, {
              [styles.offline]:
                integrationStatuses[integration.toLocaleLowerCase()] ===
                "offline",
            })}
            onClick={() => onSort(`${integration}_sum`)}
          >
            <img
              className={cn({
                [styles.fullIcon]:
                  integrationIcons[integration.toLocaleLowerCase()].full,
              })}
              src={integrationIcons[integration.toLocaleLowerCase()].img}
              alt=""
            />
            {integrationStatuses[integration.toLocaleLowerCase()] ===
              "offline" && <div className={styles.offline} />}
          </div>
        </div>
      </PortalTooltip>
    ),
    key: integration.toLocaleLowerCase(),
    dataIndex: integration.toLocaleLowerCase(),
    render: (data) => (
      <>
        <PortalTooltip
          text={
            <div className={cn(styles.tooltip)}>
              <span className={styles.tooltipIntegrationName}>
                {integration.replace("outlook", "Exchange Online")} Activities:
              </span>
              <br />
              {Object.keys(data)?.map((key) => {
                if (
                  key.includes(integration.toLocaleLowerCase()) &&
                  !key.includes("_sum") &&
                  !key.includes("_person_integrated")
                ) {
                  return data[
                    `${integration.toLocaleLowerCase()}_person_integrated`
                  ] == 1 ? (
                    <div className={styles.tooltipNumber}>
                      {data[key]} {key.split("_").join(" ")}
                    </div>
                  ) : (
                    <div className={styles.tooltipNumber}>
                      {key
                        .split("_")
                        .join(" ")
                        .replace("outlook", "exchange online")}
                      : N/A
                    </div>
                  );
                }
                return null;
              })}
            </div>
          }
        >
          <div className={styles.integration}>
            <span className={styles.statNumber}>
              {data[`${integration.toLocaleLowerCase()}_person_integrated`] == 1
                ? data[`${integration.toLocaleLowerCase()}_sum`] || "0"
                : "N/A"}
            </span>
          </div>
        </PortalTooltip>
      </>
    ),
  }));

const getColumns = (
  integrationTabs,
  sort,
  onSort,
  integrationStatuses,
  handleShowModal,
  team
) => [
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "name",
        })}
      >
        <span onClick={() => onSort("name")}>
          {sort.sort_by === "name" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("name")}>Employee</span>
      </div>
    ),
    key: "name",
    dataIndex: "data",
    render: (data) => {
      const tags = textToArrayFormatter(data.tags);
      return (
        <div className={styles.userInfo}>
          <UserDetails user={data} />
          <div className={styles.tags}>
            {tags?.split(",").map(
              (tag) =>
                tag && (
                  <Tag
                    key={tag}
                    name={tag}
                    random
                  />
                )
            )}
          </div>
        </div>
      );
    },
  },
  {
    title: team ? (
      <div className={styles.scoreTitle}>
        Score{" "}
        <Icon
          className={styles.infoIcon}
          name="info"
        />
      </div>
    ) : null,
    key: "score",
    dataIndex: "score",
    render: (score) => {
      if (!team) return null;
      return score === null ? (
        <button
          className={styles.scoreBtn}
          onClick={handleShowModal}
        >
          <Image
            src="/images/content/dashboard-score.svg"
            className={styles.scoreIcon}
          />{" "}
          Set Score
        </button>
      ) : (
        <span className={styles.scoreNumber}>{score}</span>
      );
    },
  },
  {
    title: (
      <div
        className={cn(styles.col, {
          [styles.selected]: sort.sort_by === "total_activities",
        })}
      >
        <span onClick={() => onSort("total_activities")}>
          {sort.sort_by === "total_activities" && sort.sort_order === "desc" ? (
            <SortAscIcon />
          ) : (
            <SortDescIcon />
          )}
        </span>
        <span onClick={() => onSort("total_activities")}>
          Total
          <Icon
            className={styles.infoIcon}
            name="info"
          />
        </span>
      </div>
    ),
    key: "total_activities",
    dataIndex: "total_activities",
    render: (totalActivities) => (
      <div className={styles.total}>
        <span className={styles.statNumber}>{totalActivities}</span>
      </div>
    ),
  },
  ...integrationColumns(integrationTabs, sort, onSort, integrationStatuses),
];

export default getColumns;
