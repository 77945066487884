import cn from "classnames";
import { Link } from "react-router-dom";

import { Icon, Image } from "components";

import styles from "./EmptyState.module.sass";

const EmptyState = ({ team }) => (
  <div className={styles.empty}>
    <Icon
      className={styles.clock}
      name="dashed-clock"
      size="24"
      viewBox="0 0 20 20"
    />
    <p className="emptyTitle">No results so far.</p>
    <p className="emptyTitle">
      Add an integration in order to calculate metrics.
    </p>
    <Link to={`/team/${team?.name}/${team?.id}`}>
      <button className={cn("button", styles.addBtn)}>
        <Image
          src="/images/content/add-circled.png"
          className={styles.addIcon}
          width={200}
          height={200}
        />
        Add an integration
      </button>
    </Link>
  </div>
);

export default EmptyState;
