import React, { useState, useEffect } from "react";

import { GoogleLogin } from "@react-oauth/google";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Avatar,
  Checkbox,
  CustomCarousel,
  Cleaner,
  Image,
  Loader,
  TextInput,
} from "components";
import { login, googleLogin } from "slices/appSlice";

import msalInstance, { initializeMSAL } from "./msalConfig";
import styles from "./SignIn.module.sass";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState({ username: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);

  console.log(
    "process.env.REACT_APP_GOOGLE_CLIENT_ID",
    process.env.REACT_APP_GOOGLE_CLIENT_ID
  );

  const [isMsLoading, setIsMsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  // We need to initialize MSAL before we can use it to log in
  useEffect(() => {
    const init = async () => {
      await initializeMSAL();
      setIsInitialized(true);
    };

    init();
  }, []);

  useEffect(() => {
    const savedUsername = localStorage.getItem("rememberedUsername");
    if (savedUsername) {
      setValues((prev) => ({ ...prev, username: savedUsername }));
      setRememberMe(true);
    }
  }, []);

  const handleSubmit = () => {
    dispatch(login(values));
    if (rememberMe) {
      localStorage.setItem("rememberedUsername", values.username);
    } else {
      localStorage.removeItem("rememberedUsername");
    }
  };

  const valuesHandler = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleRememberMeChange = () => {
    setRememberMe((prev) => !prev);
  };

  const { isLoggedIn, confirmationToken, token, error, loading } = useSelector(
    (state) => state.app
  );

  useEffect(() => {
    if (isLoggedIn) {
      window.location.href = "/";
    }
    if (!isLoggedIn && confirmationToken && !token) {
      navigate("/sign-up");
    }
  }, [isLoggedIn, confirmationToken, token, navigate]);

  const handleSuccess = async (response) => {
    if (response.credential) {
      dispatch(googleLogin(response));
    } else {
      console.error("Google Login failed:", response);
      toast.error("Google Login failed");
    }
  };

  const handleError = () => {
    console.error("Google Login Failed");
  };

  const handleMicrosoftLogin = async () => {
    setIsMsLoading(true);

    if (!isInitialized) return;

    try {
      const loginResponse = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "email"],
      });

      const accessToken = loginResponse.accessToken;
      console.log("ms Access Token:", accessToken);

      // Send token to the backend
      const response = await fetch(
        "https://dev.metra.ai/api/v1/auth/microsoft_graph",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: accessToken }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Ms Login successful:", data);
      } else {
        console.error("Backend error:", await response.text());
      }
    } catch (error) {
      console.error("Login failed:", error);
    } finally {
      setIsMsLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.col}>
        <Cleaner />
        <div className={styles.wrapper}>
          <Link
            className={styles.logo}
            to="/"
          >
            <Image
              className={styles.pic}
              src="/images/logo-dark.png"
              srcDark="/images/logo-light.png"
              alt="Core"
            />
          </Link>
          <div className={cn("h4", styles.title)}>Login</div>
          <div className={styles.body}>
            <div className={styles.subtitle}>Input your credentials below.</div>
            {error && <div className={styles.errorNote}>{error}</div>}
            <hr className={styles.horizontalLine} />
            <TextInput
              className={styles.field}
              name="username"
              type="email"
              placeholder="Username or email"
              required
              icon="profile-circle"
              value={values.username}
              onChange={valuesHandler}
            />
            <TextInput
              className={styles.field}
              name="password"
              type="password"
              placeholder="Password"
              required
              icon="lock"
              value={values.password}
              onChange={valuesHandler}
            />
            <div className={styles.checkbox}>
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <span className={styles.remember}>Remember me</span>
            </div>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              {loading && (
                <Loader
                  className={styles.loader}
                  white
                />
              )}
              <span>Login</span>
            </button>
            {/* TODO: Wait for backend */}
            {/* <div className={styles.hrText}>
              <span>or</span>
            </div> */}
            {/* <button className={cn("button", styles.gmailBtn)}>
              <Avatar
                src="/images/integration/google.png"
                className={styles.btnIcon}
              />
              <span>Login with Gmail</span>
            </button> */}
            {/* <GoogleLogin
              onSuccess={handleSuccess}
              onError={handleError}
              useOneTap
              className={cn("button", styles.gmailBtn)}
              cookiePolicy={"single_host_origin"}
            />

            <button
              className={cn("button", styles.msBtn)}
              onClick={handleMicrosoftLogin}
              disabled={isMsLoading}
            >
              <Avatar
                src="/images/integration/ms-365.png"
                className={styles.btnIcon}
              />
              <span>Login with Microsoft 365</span>
            </button> */}
            <div className={styles.info}>
              Don’t have an account?{" "}
              <Link
                className={styles.link}
                to="/sign-up"
              >
                Sign up
              </Link>
            </div>
            <div className={styles.info}>
              Forgot your password?{" "}
              <Link
                className={styles.link}
                to="/reminder"
              >
                Request code
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.col}>
        <CustomCarousel />
      </div>
    </div>
  );
};

export default SignIn;
