import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID}`,
    redirectUri: "https://dev.metra.ai/",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export const initializeMSAL = async () => {
  try {
    await msalInstance.initialize();
    console.log("MSAL initialized successfully.");
  } catch (error) {
    console.error("Error initializing MSAL:", error);
  }
};

export default msalInstance;
