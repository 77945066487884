import React, { useState, useEffect } from "react";

import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Icon, Avatar, Checkbox, Image } from "components";
import { getScores, getTeamScores, updateTeamScores } from "slices/scoreSlice";

import styles from "./Calculator.module.sass";
import EditButton from "./EditButton";
import EmptyState from "./EmptyState/EmptyState";

const Calculator = ({ handleClose, team }) => {
  const dispatch = useDispatch();

  const metrics = useSelector((state) => state.score.metrics);
  const teamMetrics = useSelector((state) => state.score.teamMetrics);

  const [isEditable, setIsEditable] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [showMore, setShowMore] = useState({});
  const [isError, setIsError] = useState(false);

  const [localMetrics, setLocalMetrics] = useState({});

  useEffect(() => {
    if (teamMetrics) {
      setLocalMetrics({ ...teamMetrics });
    }
  }, [teamMetrics]);

  useEffect(() => {
    if (!metrics) dispatch(getScores());
  }, [dispatch, metrics]);

  useEffect(() => {
    if (team?.id) dispatch(getTeamScores(team.id));
  }, [dispatch, team]);

  useEffect(() => {
    if (metrics) {
      const initialShowMore = {};

      metrics.forEach((integration) => {
        const integrationKey = integration.name.toLowerCase();
        initialShowMore[integrationKey] = false;
      });

      setShowMore(initialShowMore);
    }
  }, [metrics, teamMetrics]);

  const handleToggle = (integration) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [integration]: !prevShowMore[integration],
    }));
  };

  const handlePercentageChange = (value, integrationKey) => {
    setLocalMetrics({
      ...localMetrics,
      [`${integrationKey}_weight`]: value,
    });
  };

  const validatePercentages = () => {
    const total = activeMetrics.reduce((acc, metric) => {
      const value = localMetrics[`${metric.name.toLowerCase()}_weight`] || 0;
      return acc + +value;
    }, 0);

    setTotalPercentage(total);
    return total === 100;
  };

  const activeMetrics = team?.integrations?.map((integration) => {
    const integrationName = integration?.name?.toLowerCase();
    const foundedMetric = metrics?.find(
      (metric) => metric?.name?.toLowerCase() === integrationName
    );

    if (!foundedMetric) return null;
    return {
      ...foundedMetric,
      integrationId: integration.id,
    };
  });

  const handleSave = () => {
    const isValid = validatePercentages();
    if (isValid) {
      setIsError(false);
      setIsEditable(false);
      const configs = activeMetrics.map((metric) => {
        return {
          id: metric.integrationId,
          weight: localMetrics[`${metric?.name?.toLowerCase()}_weight`] || 0,
          metrics: localMetrics[`${metric?.name?.toLowerCase()}_stats`]
            ? localMetrics[`${metric?.name?.toLowerCase()}_stats`]?.split(",")
            : [],
        };
      });

      dispatch(
        updateTeamScores({
          teamId: team.id,
          configs: JSON.stringify(configs),
        })
      );
    } else {
      setIsError(true);
    }
  };

  const handleCancel = () => {
    setIsEditable(false);
    setIsError(false);
  };

  const handleCheckbox = (integrationKey, metric) => {
    const newIntegrationStats = localMetrics[`${integrationKey}_stats`]
      ? localMetrics[`${integrationKey}_stats`].toLowerCase().split(",")
      : [];

    if (newIntegrationStats.includes(metric.toLowerCase())) {
      newIntegrationStats.splice(newIntegrationStats.indexOf(metric.toLowerCase()), 1);
    } else {
      newIntegrationStats.push(metric);
    }

    setLocalMetrics({
      ...localMetrics,
      [`${integrationKey}_stats`]: newIntegrationStats.join(","),
    });
  };

  return (
    <Modal
      visible={true}
      onClose={handleClose}
      outerClassName={styles.modal}
    >
      <div className={cn("title-purple", styles.title)}>
        Metra Score
        <span>
          Prioritize the most important statistics to gauge your Team&apos;s
          performance
        </span>
      </div>
      <hr />

      <div className={styles.modalBody}>
        <div className={styles.header}>
          <div>Service</div>
          <div>
            Weight
            <Icon
              className={styles.infoIcon}
              name="info"
            />
          </div>
          <div>
            Stats calculated
            <Icon
              className={styles.infoIcon}
              name="info"
            />
          </div>
        </div>

        {activeMetrics?.length ? (
          activeMetrics.map((integration) => {
            if (!integration) return null;

            const integrationKey = integration.name.toLowerCase();
            const visibleMetrics = showMore[integrationKey]
              ? integration.metrics.length - 1
              : 5;

            return (
              <div
                key={integrationKey}
                className={styles.integrationCard}
              >
                <div className={cn(styles.col, styles.avatarContainer)}>
                  <Avatar
                    className={styles.avatar}
                    src={`/images/integration/${integrationKey}.png`}
                  />
                  {integration.name}
                </div>
                <div className={cn(styles.col, styles.editBtnWrapper)}>
                  <EditButton
                    isEditable={isEditable}
                    isError={isError}
                    handlePercentageChange={handlePercentageChange}
                    percentage={localMetrics[`${integrationKey}_weight`] || 0}
                    integrationKey={integrationKey}
                  />
                </div>

                <div
                  className={cn(styles.col, styles.stats, {
                    [styles.editable]: isEditable,
                  })}
                >
                  {integration?.metrics
                    .slice(0, visibleMetrics)
                    .map((metric, index) => {
                      const value = !!localMetrics[`${integrationKey}_stats`]
                        ?.toLowerCase()
                        ?.includes(metric.toLowerCase());
                      if (!isEditable && !value) return null;
                      return (
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCheckbox(integrationKey, metric);
                          }}
                          className={cn(styles.metric, {
                            [styles.checked]: value,
                            [styles.unchecked]: !value,
                          })}
                        >
                          {isEditable && (
                            <Checkbox
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              className={styles.checkbox}
                              value={value}
                            />
                          )}
                          {metric}
                        </div>
                      );
                    })}

                  {((isEditable &&
                    integration.metrics.length > visibleMetrics) ||
                    localMetrics[`${integrationKey}_stats`]?.split(",")
                      ?.length > visibleMetrics) && (
                    <button
                      className={cn(
                        "button button-small button-transparent",
                        styles.seeMoreBtn
                      )}
                      onClick={() => handleToggle(integrationKey)}
                    >
                      {showMore[integrationKey] ? "See Less" : "See More"}
                      <Icon
                        name="arrow-down"
                        className={
                          showMore[integrationKey] ? styles.seeLessArrow : ""
                        }
                      />
                    </button>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <EmptyState team={team} />
        )}
      </div>

      <div className={styles.modalFooter}>
        <div>
          <Image src="/images/content/double-tick.png" />6 Services
        </div>
        <div className={styles.total}>
          <span>
            <Image src="/images/content/double-tick.png" />
            Must equal 100%
          </span>
          {isError && (
            <div className={styles.errorMessage}>
              <span>Weight is equal {totalPercentage}%</span>
            </div>
          )}
        </div>
        <div>
          <div>
            <Image src="/images/content/double-tick.png" />
            36 Stats being calculated
          </div>
          {!isEditable ? (
            <button
              className="button button-small"
              onClick={() => setIsEditable(true)}
            >
              Edit Calculator
            </button>
          ) : (
            <div className={styles.editableBtns}>
              <button
                className={cn(
                  "button button-small button-stroke",
                  styles.cancel
                )}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="button button-small"
                onClick={handleSave}
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Calculator;
