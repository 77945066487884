import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiV2 } from "../app/api";

const initialState = {
  status: "idle",
  loading: false,
  error: null,
  metrics: null,
  teamMetrics: {},
};

export const score = createSlice({
  name: "score",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getScores.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getScores.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.metrics = action.payload.data;
      })
      .addCase(getScores.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(getTeamScores.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getTeamScores.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.teamMetrics = {
          ...action.payload.data,
          id: action.meta.arg,
        };
      })
      .addCase(getTeamScores.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        state.teamMetrics = null;
      });
  },
});

export const getScores = createAsyncThunk("getScores", async () => {
  const response = await apiV2.get(`/scores`);
  return response;
});

export const getTeamScores = createAsyncThunk(
  "getTeamScores",
  async (teamId) => {
    const response = await apiV2.get(`/scores/${teamId}`);
    return response;
  }
);

export const updateTeamScores = createAsyncThunk(
  "updateTeamScores",
  async (data) => {
    const { teamId, configs } = data;
    const response = await apiV2.put(
      `/scores?section_tag_id=${teamId}&integrations=${configs}`
    );
    return response;
  }
);

export default score.reducer;
