import React from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import App from "./App";
import { store } from "./app/store";

import "./assets/css/index.css";
import "./assets/css/app.css";
import "react-toastify/dist/ReactToastify.css";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider
    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
  >
    <React.StrictMode>
      <Provider store={store}>
        <ToastContainer autoClose="2000" />
        <App />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
