import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../app/api";

const token = localStorage.getItem("token");
const COLUMN_ALIAS = {
  num_emails_sent: "Emails sent",
  num_spam_emails_received: "Spam emails received",
  num_emails_received: "Emails received",
  gmail_used_quota_in_mb: "Used quota in mb",
  days_active: "Active days",
  messages_posted: "Messages posted",
  reactions_added: "Reactions added",
  commits: "Commits",
  change_requests: "Change requests",
  comments_to_others: "Commented",
  approvals_given: "Approvals given",
  comments_received: "Comments received",
  rejections_given: "Rejections given",
  approvals_received: "Approvals received",
  rejections_received: "Rejections received",
  review_requests: "Review Requests",
  comments: "Comments",
  tickets_created: "Tickets created",
  tickets_started: "Started working",
  tickets_completed: "Tickets completed",
  time_spent: "Time spent",
  hours_logged: "Hours logged",
  minutes_spent: "Minutes spent",
  edits: "Edited",
  creations: "Created",
  publishes: "Published",
  meetings_attended: "Meetings attended",
  messages_sent: "Messages sent",
  time_spent_minutes: "Minutes spent",
  files_shared: "Files shared",
  meetings_hosted: "Meetings hosted",

  meetings: "Meetings",
  organized_meetings: "Organized meetings",
  attended_meetings: "Attended meetings",
  adhoc_organized_meetings: "Adhoc attended meetings",
  adhoc_attended_meetings: "Adhoc organized meetings",
  scheduled_ontime_organized_meetings: "Scheduled ontime organized meetings",
  scheduled_ontime_attended_meetings: "Scheduled ontime attended meetings",
  scheduled_recurring_organized_meetings:
    "Scheduled recurring organized meetings",
  scheduled_recurring_attended_meetings:
    "Scheduled recurring attended meetings",
  urgent_messages: "Urgent messages",
  post_messages: "Post messages",
  reply_messages: "Reply messages",
  audio_duration_seconds: "Audio Duration",
  video_duration_seconds: "Video Duration",
  screenshare_duration_seconds: "Screen share duration",

  sent: "Mails sent",
  received: "Mails received",
  read: "Mails read",
  meetings_created: "Meetings created",
  meetings_interacted: "Meetings interacted",

  collapsed: false,
  currentUser: null,
};
const STATUS_COLORS = ["#FFBC99", "#CABDFF", "#B1E5FC", "#B5E4CA", "#FFD88D"];
const STATUS_ICONS = ["store", "diamond", "mouse", "multiselect", "mobile"];

const initialState = {
  darkMode: false,
  intervals: ["Last 7 days", "Last 30 days", "Last 365 days"],
  status: "idle",
  confirmationToken: null,
  loading: false,
  isLoggedIn: !!token,
  token: token ? token : null,
  error: null,
  columnNames: COLUMN_ALIAS,
  statusColors: STATUS_COLORS,
  statusIcons: STATUS_ICONS,
  onboardingInProgress: false,

  selectedFilterInterval: "Last 30 days",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    logOut: (state) => {
      localStorage.removeItem("token");
      api.defaults.headers.common["Authorization"] = null;
      state.status = "idle";
      state.confirmationToken = null;
      state.loading = false;
      state.isLoggedIn = false;
      state.token = null;
      state.error = null;
      window.location.reload();
    },
    clear: (state) => {
      state.status = "idle";
      state.loading = false;
      state.error = null;
    },
    changeOnboardingInProgress: (state, action) => {
      state.onboardingInProgress = action.payload;
    },
    setFilterInterval: (state, { payload }) => {
      state.selectedFilterInterval = payload;
    },
    setCollapsed: (state, { payload }) => {
      state.collapsed = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(register.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        window.localStorage.removeItem("onboardedOnce");

        state.status = "succeeded";
        state.loading = false;
        state.confirmationToken = action.payload?.data?.token
          ? action.payload?.data?.token
          : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(login.pending, (state) => {
        localStorage.removeItem("token");
        state.status = "loading";
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        localStorage.setItem("onboarded", action.payload?.data?.onboarded);
        localStorage.setItem("super_admin", action.payload?.data?.admin);
        localStorage.setItem("is_editor", action.payload?.data?.is_editor);

        state.onboardingInProgress =
          action.payload?.data?.onboarded === "false" ? true : false;

        action.payload?.data?.token && !action.payload?.data?.error
          ? localStorage.setItem("token", action.payload?.data?.token)
          : localStorage.removeItem("token");
        action.payload?.data?.token && !action.payload?.data?.error
          ? (api.defaults.headers.common["Authorization"] =
              action.payload?.data?.token)
          : (api.defaults.headers.common["Authorization"] = null);
        state.status = action.payload?.data?.error ? "failed" : "succeeded";
        state.loading = false;
        state.isLoggedIn =
          action.payload?.data?.token && !action.payload?.data?.error
            ? true
            : false;
        state.token =
          action.payload?.data?.token && !action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.confirmationToken =
          action.payload?.data?.token && action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(login.rejected, (state, action) => {
        localStorage.removeItem("token");
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        state.isLoggedIn = false;
      })

      // Google Login
      .addCase(googleLogin.pending, (state) => {
        localStorage.removeItem("token");
        state.status = "loading";
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        console.log("googleLogin.fulfilled", action.payload);

        localStorage.setItem("onboarded", action.payload?.data?.onboarded);
        localStorage.setItem("super_admin", action.payload?.data?.admin);
        localStorage.setItem("is_editor", action.payload?.data?.is_editor);

        state.onboardingInProgress =
          action.payload?.data?.onboarded === "false" ? true : false;

        action.payload?.data?.token && !action.payload?.data?.error
          ? localStorage.setItem("token", action.payload?.data?.token)
          : localStorage.removeItem("token");
        action.payload?.data?.token && !action.payload?.data?.error
          ? (api.defaults.headers.common["Authorization"] =
              action.payload?.data?.token)
          : (api.defaults.headers.common["Authorization"] = null);
        state.status = action.payload?.data?.error ? "failed" : "succeeded";
        state.loading = false;
        state.isLoggedIn =
          action.payload?.data?.token && !action.payload?.data?.error
            ? true
            : false;
        state.token =
          action.payload?.data?.token && !action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.confirmationToken =
          action.payload?.data?.token && action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(googleLogin.rejected, (state, action) => {
        localStorage.removeItem("token");
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        state.isLoggedIn = false;
      })
      .addCase(confirm.pending, (state) => {
        localStorage.removeItem("token");
        state.status = "loading";
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(confirm.fulfilled, (state, action) => {
        action.payload?.data?.token && !action.payload?.data?.error
          ? localStorage.setItem("token", action.payload?.data?.token)
          : localStorage.removeItem("token");
        state.status = action.payload?.data?.error ? "failed" : "succeeded";
        state.loading = false;
        state.isLoggedIn =
          action.payload?.data?.token && !action.payload?.data?.error
            ? true
            : false;
        state.token =
          action.payload?.data?.token && !action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.confirmationToken =
          action.payload?.data?.token && action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(confirm.rejected, (state, action) => {
        localStorage.removeItem("token");
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        state.isLoggedIn = false;
      })
      .addCase(reSend.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(reSend.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.confirmationToken = action.payload?.data?.token
          ? action.payload?.data?.token
          : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(reSend.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(requestReminder.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(requestReminder.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.confirmationToken = action.payload?.data?.token
          ? action.payload?.data?.token
          : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(requestReminder.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(confirmReminder.pending, (state) => {
        localStorage.removeItem("token");
        state.status = "loading";
        state.loading = true;
        state.isLoggedIn = false;
      })
      .addCase(confirmReminder.fulfilled, (state, action) => {
        action.payload?.data?.token && !action.payload?.data?.error
          ? localStorage.setItem("token", action.payload?.data?.token)
          : localStorage.removeItem("token");
        state.status = action.payload?.data?.error ? "failed" : "succeeded";
        state.loading = false;
        state.isLoggedIn =
          action.payload?.data?.token && !action.payload?.data?.error
            ? true
            : false;
        state.token =
          action.payload?.data?.token && !action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.confirmationToken =
          action.payload?.data?.token && action.payload?.data?.error
            ? action.payload?.data?.token
            : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;

        window.location.href = "/";
      })
      .addCase(confirmReminder.rejected, (state, action) => {
        localStorage.removeItem("token");
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        state.isLoggedIn = false;
      })
      .addCase(requestPasswordChange.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(requestPasswordChange.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.confirmationToken = action.payload?.data?.token
          ? action.payload?.data?.token
          : null;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(requestPasswordChange.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(confirmPasswordChange.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(confirmPasswordChange.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.error = action.payload?.data?.error
          ? action.payload?.data?.error
          : null;
      })
      .addCase(confirmPasswordChange.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.currentUser = action.payload?.data?.user;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  logOut,
  clear,
  changeOnboardingInProgress,
  setFilterInterval,
  setCollapsed,
} = appSlice.actions;

export const register = createAsyncThunk("register", async (data) => {
  const response = await api.post("auth/registrations", data);
  return response;
});

export const login = createAsyncThunk("login", async (data) => {
  const response = await api.post("auth/sessions", data);
  return response;
});

export const googleLogin = createAsyncThunk("googleLogin", async (data) => {
  const response = await api.post("/auth/google_oauth2", {
    token: data.credential,
  });
  return response;
});

export const confirm = createAsyncThunk("confirm", async (data) => {
  const response = await api.post("auth/confirmations", data);
  return response;
});

export const reSend = createAsyncThunk("reSend", async (confirmationToken) => {
  const response = await api.post("auth/resend", { confirmationToken });
  return response;
});

export const requestReminder = createAsyncThunk(
  "requestReminder",
  async ({ username }) => {
    const response = await api.post("auth/unlocks", { username });
    return response;
  }
);

export const confirmReminder = createAsyncThunk(
  "confirmReminder",
  async (data) => {
    const response = await api.post("auth/resolve", data);
    return response;
  }
);

export const requestPasswordChange = createAsyncThunk(
  "requestPasswordChange",
  async (data) => {
    const response = await api.post("auth/passwords", data);
    return response;
  }
);

export const confirmPasswordChange = createAsyncThunk(
  "confirmPasswordChange",
  async (data) => {
    const response = await api.post("auth/resolvePass", data);
    return response;
  }
);

export const confirmOnboarding = createAsyncThunk(
  "confirmOnboarding",
  async () => {
    const response = await api.put("auth/onboard");
    return response;
  }
);

export const getCurrentUser = createAsyncThunk("getCurrentUser", async () => {
  const response = await api.get("/auth/current_user");
  return response;
});

export default appSlice.reducer;
