import React, { useEffect, useState } from "react";

import cn from "classnames";

import styles from "./EditButton.module.sass";

const EditButton = ({
  handlePercentageChange,
  percentage,
  integrationKey,
  isEditable,
  isError,
}) => {
  const [inputValue, setInputValue] = useState(percentage);

  useEffect(() => {
    setInputValue(percentage);
  }, [percentage]);

  const handleInputChange = (event) => {
    let value = event.target.value;

    if (value === "") {
      setInputValue("");
      return;
    }

    if (/^\d*\.?\d{0,2}$/.test(value)) {
      value = Math.min(100, Math.max(0, parseFloat(value)));
      setInputValue(value);
      handlePercentageChange(value, integrationKey);
    }
  };

  const handleBlur = () => {
    if (inputValue === "") {
      setInputValue(0);
      handlePercentageChange(0, integrationKey);
    }
  };

  const increment = () => {
    const newValue = Math.min(100, inputValue + 1);
    setInputValue(newValue);
    handlePercentageChange(newValue, integrationKey);
  };

  const decrement = () => {
    const newValue = Math.max(0, inputValue - 1);
    setInputValue(newValue);
    handlePercentageChange(newValue, integrationKey);
  };

  return (
    <div
      className={cn({
        [styles.editablePercentage]: isEditable,
        [styles.percentage]: !isEditable,
        [styles.errorPercentage]: isError,
      })}
    >
      <div onClick={decrement}>
        {isEditable && <button className={styles.changeBtn}>-</button>}
      </div>

      <div>
        {isEditable ? (
          <input
            type="number"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className={styles.percentageInput}
            min="0"
            max="100"
            step="1"
          />
        ) : (
          <span>{percentage}</span>
        )}
        <span className={styles.percentageSign}>%</span>
      </div>

      <div onClick={increment}>
        {isEditable && <button className={styles.changeBtn}>+</button>}
      </div>
    </div>
  );
};

export default EditButton;
